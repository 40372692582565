import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import LinearProgress from '@material-ui/core/LinearProgress';
import posed, { PoseGroup } from 'react-pose';

const RouteContainer = posed.div({
	enter: { opacity: 1, delay: 10, beforeChildren: true },
	exit: { opacity: 0 },
});

const loading = <LinearProgress />;
const Login = React.lazy(() => import('../views/Login'));
const LoginAlterno = React.lazy(() => import('../views/LoginAlterno'));
const Home = React.lazy(() => import('../views/VistaHome'));

export default function App() {
	return (
		<BrowserRouter>
			<Route
				render={({ location }) => {
					return (
						<>
							<PoseGroup>
								<RouteContainer key={location.key || location.pathname}>
									<React.Suspense fallback={loading}>
										<Switch>
											<Route exact path='/' render={(props) => <Login {...props} />} />
											<Route exact path='/login-guest' render={(props) => <LoginAlterno {...props} />} />
											<Route exact path='/home' render={(props) => <Home {...props} />} />
										</Switch>
									</React.Suspense>
								</RouteContainer>
							</PoseGroup>
						</>
					);
				}}
			/>
		</BrowserRouter>
	);
}
